
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "components/toggle-slider";

.btn_all{width: 100%}


.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
button:disabled,
button[disabled],
button[disabled]:hover{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.morris-hover{position:absolute;z-index:1000;}
.morris-hover.morris-default-style{border-radius:10px;padding:6px;color:#666;background:rgba(255, 255, 255, 0.8);border:solid 2px rgba(230, 230, 230, 0.8);font-family:sans-serif;font-size:12px;text-align:center;}
.morris-hover.morris-default-style .morris-hover-row-label{font-weight:bold;margin:0.25em 0;}
.morris-hover.morris-default-style .morris-hover-point{white-space:nowrap;margin:0.1em 0;}



/* iCheck plugin Flat skin, green
----------------------------------- */
.icheckbox_flat-green,
.iradio_flat-green {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  background: url(/img/green.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_flat-green {
  background-position: 0 0;
}
.icheckbox_flat-green.checked {
  background-position: -22px 0;
}
.icheckbox_flat-green.disabled {
  background-position: -44px 0;
  cursor: default;
}
.icheckbox_flat-green.checked.disabled {
  background-position: -66px 0;
}

.iradio_flat-green {
  background-position: -88px 0;
}
.iradio_flat-green.checked {
  background-position: -110px 0;
}
.iradio_flat-green.disabled {
  background-position: -132px 0;
  cursor: default;
}
.iradio_flat-green.checked.disabled {
  background-position: -154px 0;
}
.black_td{
  background: #222;
  color:#FFF;
  float:left;
  min-width: 15px;
}
td div{
  text-align: center;
}
/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-green,
  .iradio_flat-green {
    background-image: url(/img/green@2x.png);
    -webkit-background-size: 176px 22px;
    background-size: 176px 22px;
  }
}

table.comparisons {
  color: #FFF;
  width: 100%;

  th {
    color: #000;
    text-align: center;
  }

  tr {
    border: 2px solid #FFF;
  }

  .row0 {
    text-align: center;
  }

  td.cell-data {
    border: 2px solid #FFF;
    text-align: center;
    color: #222;
  }

  td.cell-no-data {
    background-color: #999;
  }

  span.span-block {
    display: block;
    border-left: 23px solid #848484;
  }

  span.span-negative {
    margin-left: -20px;
    float:left;
  }

  .cell-white {
    border-right: 2px solid #DDD;
    text-align: center;
  }

  .unused {
    display: none;
  }
}

// Boxes in home
.box-centered {
  text-align: center;
}

.box iframe {
  border:0;
  padding:1em;
  max-width: 100%;
}

.panel-data-access {
  padding-top:0 !important;
  text-align: center;
}

.maximeter-heading {
  margin-bottom: 0;
}

select.form-control.select-year {
  max-width: 175px;
}

// Utility classes
.pl-15 {
  padding-left: 15px;
}

form.destroy {
  display: inline-block;
}

.form-wrapper {
  padding: 20px;
}

.field_wrapper div {
  margin-bottom:10px;
}

.remove_button, .add_button {
  padding:10px;
  vertical-align: text-bottom;
}

.electrical-reading {
  dt {
    display:table-cell;
    vertical-align:middle;
    width: 100px !important;
    padding-right:5px;
  }

  dd {
    display:table-cell;
    vertical-align:middle;
    margin: 0;
  }
}

.inline-checkbox {
  display: inline-block;
}